import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import ImageClient from '../../helpers/imageHandler'
import { startCase } from '../../helpers/normalizer'
import reactStringReplace from 'react-string-replace'
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'

import './recipes.scss'

import {
    setSelectedRecipe,
    fetchRecipeDetails,
    resetRecipeDetails,
    resetRelatedRecipes,
    fetchRelatedRecipes,
} from './actions'

import { setSelectedPlusOne } from '../plus-one/actions'
import { triggerGAEvent } from '../../helpers/googleTagManager'
import MetaTags from '../../components/meta'

const ModalPlusOne = lazy(() => import('../../components/ModalPlusOne'))
const ModalEmailIt = lazy(() => import('../../components/ModalEmailIt'))

class RecipeSingle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayPlusOneModal: false,
            displayEmailItModal: false,
            isRecipesByTypesFetched: false,
            email: {
                type: '',
                from: '',
                pdfFilePath: '',
            },
        }

        const slug = get(this.props, 'match.params.slug')
        this.props.fetchRecipeDetails(slug)

        this.openPlusOneModal = this.openPlusOneModal.bind(this)
        this.closePlusOneModal = this.closePlusOneModal.bind(this)
        this.toggleEmailItModal = this.toggleEmailItModal.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.resetRelatedRecipes()
    }

    componentDidUpdate(prevProps) {
        const { isRecipesByTypesFetched } = this.state
        const slug = get(this.props, 'match.params.slug')
        const prevSlug = get(prevProps, 'match.params.slug')
        const {
            location: { state },
            loading,
            selected,
            recipeDetails,
            recipeDressingInfo,
        } = this.props

        if (selected && slug !== prevSlug && !loading) {
            this.props.fetchRecipeDetails(slug)

            if (isRecipesByTypesFetched) this.clearRelatedData()
        }

        if (!prevProps.recipeDetails.length && recipeDetails.length) {
            if (state && state.recipeID) {
                this.props.setSelectedRecipe(state.recipeID)
            } else {
                this.props.setSelectedRecipe(this.props.recipeDetails[0].id)
            }

            if (isRecipesByTypesFetched) this.clearRelatedData()
        }

        if (selected && !isRecipesByTypesFetched) {
            const { flavors } = selected

            if (recipeDressingInfo.length) {
                this.setState({ isRecipesByTypesFetched: true })
                this.props.fetchRelatedRecipes({
                    flavors: flavors || [],
                    selected: selected.id,
                    products: recipeDressingInfo.map(({ id, code }) => ({
                        id,
                        code,
                    })),
                })
            }
        }
    }

    clearRelatedData() {
        this.props.resetRelatedRecipes()
        this.setState({ isRecipesByTypesFetched: false })
    }

    componentWillUnmount() {
        this.props.resetRecipeDetails()
    }

    closePlusOneModal() {
        this.setState({ displayPlusOneModal: false })
    }

    handlePlusOneSelection(plusOne) {
        this.props.setSelectedPlusOne(plusOne)
        this.openPlusOneModal()
    }

    openPlusOneModal() {
        this.setState({
            displayPlusOneModal: true,
        })
    }

    toggleEmailItModal(type) {
        const { selected: recipe, plusOneSelected } = this.props

        let from = ''
        let pdfFilePath = ''
        if (type === 'plus one') {
            from = 'Plus One Pop Up'
            pdfFilePath = plusOneSelected.pdfFilePath
        } else if (type === 'recipe') {
            from = 'Single Recipe Page'
            pdfFilePath = recipe.pdfFilePath
        }

        this.setState({
            displayPlusOneModal: false,
            displayEmailItModal: !this.state.displayEmailItModal,
            email: {
                type,
                from,
                pdfFilePath,
            },
        })
    }

    handleEmailItClose = () => {
        this.toggleEmailItModal()
    }

    handleGAClick(label, cb) {
        // call cb here because it could be called twice in "eventCallback" of dataLayer.push
        if (typeof cb === 'function') {
            cb()
        }

        triggerGAEvent({
            label,
            action: 'Button click',
            category: 'Single Recipe Page',
        })
    }

    renderRelatedRecipes() {
        const results = []
        const { flavors, relatedRecipes, recipeDressingInfo } = this.props
        const flavorSourceIds = flavors.map(({ id }) => id)
        const renderChilds = (sourceItem, idx) => (
            <Link
                to={{
                    pathname: `/recipes/${sourceItem.slug}`,
                    state: { recipeID: sourceItem.id },
                }}
                className="block recipe gray"
                key={`${idx}-related-recipe-${sourceItem.id}`}
            >
                <div className="block-img">
                    <img
                        src={sourceItem.details.thumbnail}
                        alt={`${idx}-recipe-${sourceItem.id}`}
                    />
                </div>
                <div className="block-txt">
                    <div className="block-title">
                        <p>{startCase(sourceItem.label)}</p>
                    </div>
                </div>
            </Link>
        )

        for (const sourceItemId of Object.keys(relatedRecipes)) {
            const sourceItems = relatedRecipes[sourceItemId]
            const isFlavorSource = flavorSourceIds.includes(sourceItemId)
            const entity = isFlavorSource
                ? flavors.find(({ id }) => id === sourceItemId)
                : recipeDressingInfo.find(({ id }) => id === sourceItemId)

            if (entity) {
                const { id, title, code, name } = entity
                const label = String(
                    isFlavorSource ? name : title
                ).toLowerCase()

                results.push(
                    <div
                        className="related-wrapper"
                        key={`${id}-related-items`}
                    >
                        <h3>More {label} recipes</h3>
                        <Link
                            className="button"
                            to={`/search-result?${code || label}#recipes`}
                        >
                            See more recipes with this{' '}
                            {code ? 'product' : 'flavor'}
                        </Link>
                        <div className="recipe-list">
                            {sourceItems.map(renderChilds)}
                        </div>
                    </div>
                )
            }
        }

        return results
    }

    renderIngredients(recipe) {
        const parseIngredient = (value, productId, plusOneId, subRecipeId) => {
            if (productId) {
                const product = recipe.related.products.find(
                    (p) => p.id === productId
                )
                if (product) {
                    return <a href={product.url}>{value}</a>
                }
            }

            if (plusOneId) {
                const plusOne = recipe.related.plusOnes.find(
                    (p) => p.id === plusOneId
                )
                if (plusOne) {
                    return (
                        <span
                            className="link"
                            onClick={(e) =>
                                this.handlePlusOneSelection(plusOne)
                            }
                        >
                            {value}
                        </span>
                    )
                }
            }

            if (subRecipeId) {
                const subRecipe = recipe.subRecipes.find(
                    (p) => p.id === subRecipeId
                )
                if (subRecipe && subRecipe.isStandAlone) {
                    return (
                        <Link to={`/recipes/${subRecipe.slug}`}>{value}</Link>
                    )
                }
                return <a href={`#${subRecipe.id}`}>{value}</a>
            }

            return value
        }

        return recipe.ingredients.map((r, idx) => {
            let className = ''
            const weight =
                r.weight && this.props.uoms.find((uom) => uom.id === r.weight)

            if (r.subRecipeId) {
                className = 'sub-recipe'
            }

            return (
                <li className={className} key={`recipe-ingredient-${idx}`}>
                    <span>
                        {r.amount} {weight ? weight.name : r.weight}
                    </span>{' '}
                    <span>
                        {parseIngredient(
                            r.ingredient,
                            r.productId,
                            r.plusOneId,
                            r.subRecipeId
                        )}
                        {r.notes && `, ${r.notes}`}
                    </span>
                </li>
            )
        })
    }

    renderDirections(steps) {
        return steps.map((r, idx) =>
            r.step ? (
                <li key={`recipe-step-${idx}`}>
                    {reactStringReplace(r.step, /\[(.*?)\]/, (match, i) => {
                        if (r.products && r.products.length) {
                            const foundedProduct = r.products.find(
                                (p) => p.code === match
                            )

                            if (foundedProduct) {
                                return (
                                    <a
                                        href={foundedProduct.url}
                                        key={`directions-product-${i}`}
                                    >
                                        {foundedProduct.title} (
                                        {foundedProduct.code})
                                    </a>
                                )
                            }
                        }

                        if (r.plusOnes && r.plusOnes.length) {
                            const foundedPlusOne = r.plusOnes.find(
                                (p) => p.title === match
                            )

                            if (foundedPlusOne) {
                                return (
                                    <span
                                        key={`directions-plusone-${i}`}
                                        className="link"
                                        onClick={(e) =>
                                            this.handlePlusOneSelection(
                                                foundedPlusOne
                                            )
                                        }
                                    >
                                        {foundedPlusOne.title}
                                    </span>
                                )
                            }
                        }

                        if (r.subRecipes && r.subRecipes.length) {
                            const foundedSub = r.subRecipes.find(
                                (s) => s.title === match
                            )

                            if (foundedSub) {
                                return (
                                    <a
                                        href={`#${foundedSub.id}`}
                                        key={`directions-sub-recipe-${i}`}
                                    >
                                        {foundedSub.title}
                                    </a>
                                )
                            }
                        }

                        return match
                    })}
                </li>
            ) : null
        )
    }

    renderSubRecipes() {
        const { selected: recipe } = this.props
        const subRecipes = recipe.subRecipes

        return subRecipes.map((r, idx) => {
            return (
                <ScrollableAnchor id={r.id} key={`sub-recipe-${idx}`}>
                    <div className="body-wrapper recipes single sub-recipe">
                        <h3>
                            {r && r.isStandAlone ? (
                                <Link to={`/recipes/${r.slug}`}>{r.title}</Link>
                            ) : (
                                r.title
                            )}
                        </h3>
                        <div className="body-wrapper recipes single">
                            <div className="info-block ingredients">
                                <p>
                                    <strong>Ingredients</strong>
                                </p>
                                <ul>{this.renderIngredients(r)}</ul>
                            </div>
                            <div className="info-block">
                                <p>
                                    <strong>Directions</strong>
                                </p>
                                <ol>{this.renderDirections(r.steps)}</ol>
                            </div>
                        </div>
                    </div>
                </ScrollableAnchor>
            )
        })
    }

    renderDressingImages() {
        const { recipeDressingInfo } = this.props

        if (recipeDressingInfo.length) {
            return (
                <div className="dressing">
                    {recipeDressingInfo.map((ent, idx) => {
                        const cls = ent.isKogi ? 'kogi' : ''
                        const fitOpts = ent.isKogi
                            ? {
                                  width: 172,
                                  height: 300,
                              }
                            : {
                                  width: 200,
                                  height: 288,
                              }

                        return (
                            <img
                                alt="dressing"
                                className={cls}
                                key={`dressing-img-${idx}`}
                                src={ImageClient.fitIn(ent.image, fitOpts)}
                            />
                        )
                    })}
                </div>
            )
        }

        return null
    }

    render() {
        const {
            location: { hash },
            relatedRecipes,
            relatedRecipesLoading,
            loading,
            selected: recipe,
            plusOneSelected,
            recipeDressingInfo,
        } = this.props
        const { email, displayPlusOneModal, displayEmailItModal } = this.state
        const shouldShowDetails = recipe && !loading

        if (hash) {
            configureAnchors({ offset: -75 })
        }

        return (
            <>
                <MetaTags
                    title={get(recipe, ['meta', 'title'])}
                    keywords={get(recipe, ['meta', 'keyword'])}
                    description={get(recipe, ['meta', 'description'])}
                />
                <div>
                    {!shouldShowDetails && (
                        <div className="body-wrapper">
                            <p>Loading ...</p>
                        </div>
                    )}

                    {shouldShowDetails && (
                        <>
                            <div className="body-wrapper recipes single">
                                <h2>{startCase(recipe.title)}</h2>
                                {this.renderDressingImages()}
                                <div className="recipe-info">
                                    {!!recipeDressingInfo.length && (
                                        <div className="row">
                                            <div className="label">
                                                dressing
                                            </div>
                                            <div className="dot-line"></div>
                                            <div className="value">
                                                {recipeDressingInfo.map(
                                                    (ent, idx) => (
                                                        <a
                                                            href={ent.url}
                                                            key={`dressing-info-${idx}`}
                                                        >
                                                            {ent.name}
                                                        </a>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!!recipe.courses.length && (
                                        <div className="row">
                                            <div className="label">
                                                menu category
                                            </div>
                                            <div className="dot-line"></div>
                                            <div className="value">
                                                {recipe.courses
                                                    .map((ent) => ent.title)
                                                    .join(', ')}
                                            </div>
                                        </div>
                                    )}
                                    {!!recipe.dishes.length && (
                                        <div className="row">
                                            <div className="label">
                                                dish category
                                            </div>
                                            <div className="dot-line"></div>
                                            <div className="value">
                                                {recipe.dishes
                                                    .map((ent) => ent.title)
                                                    .join(', ')}
                                            </div>
                                        </div>
                                    )}
                                    {!!recipe.related.plusOnes.length && (
                                        <div className="row">
                                            <div className="label">
                                                plus one
                                            </div>
                                            <div className="dot-line"></div>
                                            <div className="value">
                                                {recipe.related.plusOnes.map(
                                                    (ent, idx) => (
                                                        <div
                                                            className="link"
                                                            onClick={() => {
                                                                this.handlePlusOneSelection(
                                                                    ent
                                                                )
                                                            }}
                                                            key={`related-plus-one-${idx}`}
                                                        >
                                                            {ent.title}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="button-group">
                                    {recipe.cardFilePath ? (
                                        <button
                                            className="button"
                                            onClick={() =>
                                                this.handleGAClick(
                                                    'Get Recipe Card',
                                                    window.open(
                                                        recipe.cardFilePath
                                                    )
                                                )
                                            }
                                            data-gtm-get-recipe-card-link-a
                                        >
                                            get recipe card
                                        </button>
                                    ) : null}
                                    <button
                                        className="button"
                                        onClick={() =>
                                            this.handleGAClick(
                                                'Get PDF',
                                                window.open(recipe.pdfFilePath)
                                            )
                                        }
                                        data-gtm-get-pdf-link-a
                                    >
                                        get pdf
                                    </button>
                                    <button
                                        className="button"
                                        onClick={() =>
                                            this.handleGAClick(
                                                'Print It',
                                                window.open(recipe.pdfFilePath)
                                            )
                                        }
                                        data-gtm-print-it-link-a
                                    >
                                        print it
                                    </button>
                                    <button
                                        onClick={() =>
                                            this.handleGAClick('Email It', () =>
                                                this.toggleEmailItModal(
                                                    'recipe'
                                                )
                                            )
                                        }
                                        data-gtm-email-it-link-a
                                    >
                                        email it
                                    </button>
                                </div>
                            </div>

                            <div
                                className="recipe-img"
                                style={{
                                    backgroundImage: `url(${recipe.image})`,
                                }}
                            ></div>

                            <div className="body-wrapper recipes single">
                                <div className="info-block ingredients">
                                    <p>
                                        <strong>Ingredients</strong>
                                    </p>
                                    <ul>{this.renderIngredients(recipe)}</ul>
                                </div>
                                <div className="info-block">
                                    <p>
                                        <strong>Directions</strong>
                                    </p>
                                    <ol>
                                        {this.renderDirections(recipe.steps)}
                                    </ol>
                                </div>
                            </div>
                            {this.renderSubRecipes()}

                            {relatedRecipesLoading && (
                                <div className="loader">Loading...</div>
                            )}
                            {!relatedRecipesLoading && (
                                <div className="body-wrapper">
                                    {Object.keys(relatedRecipes).length
                                        ? this.renderRelatedRecipes()
                                        : null}
                                </div>
                            )}
                        </>
                    )}

                    {plusOneSelected && (
                        <Suspense fallback={null}>
                            <ModalPlusOne
                                visible={displayPlusOneModal}
                                onEmailItClick={() =>
                                    this.toggleEmailItModal('plus one')
                                }
                                onClose={this.closePlusOneModal}
                            />
                        </Suspense>
                    )}

                    <Suspense fallback={null}>
                        <ModalEmailIt
                            visible={displayEmailItModal}
                            type={email.type}
                            from={email.from}
                            pdfFilePath={email.pdfFilePath}
                            onClose={this.handleEmailItClose}
                        />
                    </Suspense>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    uoms: get(state, 'lookup.uoms', []),
    flavors: get(state, 'lookup.flavors', []),
    selected: get(state, 'recipes.selected', null),
    plusOneSelected: get(state, 'plusOnes.selected'),
    recipeDetails: get(state, 'recipes.details', []),
    loading: get(state, 'recipes.paging.loading', false),
    relatedRecipes: get(state, 'recipes.relatedRecipes'),
    recipeDressingInfo: get(state, 'recipes.dressingInfo', []),
    relatedRecipesLoading: get(state, 'recipes.relatedRecipesLoading'),
})

export default connect(mapStateToProps, {
    setSelectedRecipe,
    resetRecipeDetails,
    fetchRecipeDetails,
    resetRelatedRecipes,
    fetchRelatedRecipes,
    setSelectedPlusOne,
})(RecipeSingle)
