import React, { PureComponent } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Set from 'lodash/set';
import _SortBy from 'lodash/sortBy';

import { prepareGTMDataset } from '../../helpers/googleTagManager';

import './navigation.scss';

class Navigation extends PureComponent {

    navClicked(event) {
        if (event.target.nodeName === 'A') {
            // if user click on a direct link, no need to inspect - just navigate
            return;
        }

        const navPath = event.nativeEvent.path || [];

        // IE11/Edge have no idea about `path` or `composedPath`
        if (!navPath.length) {
            let target = event.target;
            while (target.parentNode !== null) {
                navPath.push(target);
                target = target.parentNode;
            }
        }

        const maxPathIndex = navPath.indexOf(event.currentTarget);

        let [ targetPath, statePath, filterValue, hashValue, subkeyValue ] = [ '', '', '', '', '' ];

        for (let idx = maxPathIndex; idx >= 0; idx--) {
            const curElement = navPath[idx];
            const { dataset: { path, hash, itemId, stateName, subkey }} = curElement;

            if (path) {
                targetPath = path
            };

            if (stateName) {
                statePath += `${stateName}.`
            };

            if (hash) {
                hashValue = hash
            };

            if (idx === 0) {
                filterValue = itemId
            };

            if (subkey) {
                subkeyValue = subkey
            };
        }

        // remove last dot
        let finalStatePath = statePath.slice(0, -1)

        if(subkeyValue) {
            // add subkey if exists: ?attribute[kosher]=Yes
            finalStatePath += `[${subkeyValue}]`
        }

        const result = {};
        Set(result, finalStatePath, filterValue);

        //HACK: top level menu item should go to /story, but we don't want childrent to inherit
        if (targetPath.endsWith('/story') && targetPath.match('products')) {
            targetPath = targetPath.slice(0, targetPath.length - 6)
        }

        if (hashValue) {
            targetPath += hashValue;
        }

        this.props.onMenuClick(targetPath, result);
    }

    renderSubMenu(menuItems) {
        const saladDressingID = '5bd97314a842a126f5517038'; // as per [KR-57]
        const firstItem = menuItems && menuItems[0];
        const maybeHasStateName = firstItem && firstItem.stateName;
        const maybeSortByDisplayOrder = firstItem && firstItem.displayOrder;
        const sortedItems = maybeHasStateName ?
            menuItems : _SortBy(menuItems, (maybeSortByDisplayOrder ? 'displayOrder' : 'name'));

        return (
            <ul>
                {sortedItems.map((itm, idx) => {
                    let submenu = null;

                    if (itm.id && (itm.id === saladDressingID))
                        return null;

                    if (itm.subItems && itm.subItems.length)
                        submenu = this.renderSubMenu(itm.subItems);

                    return <li data-state-name={itm.stateName} data-path={itm.target} data-item-id={itm.id} data-subkey={itm.subkey} data-hash={itm.hash} className={itm.class} key={idx} onClick={this.props.closeCallback}>
                        {itm.target ? <Link to={itm.target} {...prepareGTMDataset(itm.gtm)}>{itm.name}</Link> : <span data-item-id={itm.id} {...prepareGTMDataset(itm.gtm)}>{itm.name}</span>}
                        {submenu}
                    </li>
                })}
            </ul>
        )
    }

    renderMenu(menuItems) {
        return (
            <ul>
                {menuItems.map((itm, idx) => {
                    let submenu = null;

                    if (itm.subItems && itm.subItems.length) {
                        submenu = this.renderSubMenu(itm.subItems);
                    }

                    const isGetSample = itm.target && itm.target.endsWith('/get-sample');
                    const path = isGetSample ? { pathname: itm.target, state: { gaFrom: 'Nav Bar Sample Request', gaLabel: 'Sample Request Submit - Nav Bar' }} : itm.target;

                    return <li data-state-name={itm.stateName} data-path={itm.target} data-item-id={itm.id} key={idx} onClick={this.props.closeCallback}>
                        {itm.target ? <NavLink className={itm.class} to={path} {...prepareGTMDataset(itm.gtm)}>{itm.name}</NavLink> : itm.name}
                        {submenu}
                    </li>
                })}
            </ul>
        )
}

    render() {
        return (
            <nav onClick={(evt) => this.navClicked(evt)} >
                {this.renderMenu(this.props.menuItems)}
            </nav>
        );
    }
}

export default Navigation;
