export const PRODUCT_ATTRIBUTES = [
    { id: 'gluten', name: 'Gluten free', value: 'Yes' },
    { id: 'kosher', name: 'Kosher', value: 'Yes' },
    { id: 'kosherDairy', name: 'Kosher Dairy', value: 'Yes' },
    { id: 'edta', name: 'No EDTA', value: 'No' },
    { id: 'hfcs', name: 'No HFCS', value: 'No' },
    { id: 'msg', name: 'No added MSG', value: 'No' },
    { id: 'artificialFlavors', name: 'No artificial flavors', value: 'No' },
    {
        id: 'artificialPreservatives',
        name: 'No artificial preservatives',
        value: 'No',
    },
    {
        id: 'syntheticColors',
        name: 'No synthetic colors',
        value: 'No',
    },
]
