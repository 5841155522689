import _merge from 'lodash/merge';
import _clone from 'lodash/clone';

import { PRODUCT_ATTRIBUTES } from '../constants';
import { prepareGTMString } from '../helpers';
import {
    LOAD_LOOKUP_DATA_COMPLETE
} from '../actions/types';

const initialState = {
    menuItems: [
        {
            name: 'products',
            target: '/products/story',
            gtm: 'data-gtm-products-link-a',
            subItems: [
                {
                    name: 'all products',
                    class: 'no-sign',
                    target: '/products',
                    gtm: 'data-gtm-all-products-link-a'
                },
                {
                    name: 'flavor/type',
                    stateName: 'flavor',
                    gtm: 'data-gtm-flavor-type-link-a',
                    subItems: [] // to be loaded by dataset
                },
                {
                    name: 'product line',
                    stateName: 'productLines',
                    gtm: 'data-gtm-product-line-link-a',
                    subItems: []
                },
                {
                    name: 'packaging',
                    stateName: 'package',
                    gtm: 'data-gtm-packaging-link-a',
                    subItems: []
                },
                {
                    name: 'attribute',
                    stateName: 'attribute',
                    gtm: 'data-gtm-attributes-link-a',
                    // convert attibutes to menu subItems
                    subItems: PRODUCT_ATTRIBUTES.map((item) => ({
                        name: item.name,
                        id: item.value,
                        subkey: item.id,
                        gtm: prepareGTMString(item.name)
                    }))   
                }
            ]
        },
        {
            name: 'recipes & menu trends',
            target: '/recipes',
            gtm: 'data-gtm-recipes-and-menu-trends-a',
            subItems: [
                {
                    name: 'dish category',
                    hash: '#allRecipes',
                    stateName: 'dishes',
                    gtm: 'data-gtm-dish-category-link-a',
                    subItems: []
                },
                {
                    name: 'flavor category',
                    hash: '#allRecipes',
                    stateName: 'flavor',
                    gtm: 'data-gtm-flavor-category-link-a',
                    subItems: []
                },
                {
                    name: 'all recipes',
                    class: 'no-sign',
                    target: '/recipes#allRecipes',
                    gtm: 'data-gtm-all-recipes-link-a'
                }
            ]
        },
        {
            name: 'make it your own',
            target: '/plus-one',
            gtm: 'data-gtm-make-it-your-own-link-a'
        },
        {
            name: 'about us',
            target: '/about-us',
            gtm: 'data-gtm-about-us-link-a'
        },
        {
            name: 'request a sample',
            target: '/get-sample',
            class: 'button',
            gtm: 'data-gtm-request-a-sample-link-a'
        }
    ],
    flavors: [],
    productlines: [],
    packagings: [],
    dishes: [],
    trends: []
};

export default (state = initialState, action) => {

    let updatedState = _clone(state);

    switch (action.type) {
        case LOAD_LOOKUP_DATA_COMPLETE:
            if (action.payload) {
                const dataMap = [
                    {name: 'flavors', mainMenu: 0, subMenu: 1},
                    {name: 'flavors', mainMenu: 1, subMenu: 1},
                    {name: 'productLines', mainMenu: 0, subMenu: 2},
                    {name: 'packagings', mainMenu: 0, subMenu: 3},
                    {name: 'attribute', mainMenu: 0, subMenu: 4},
                    {name: 'dishes', mainMenu: 1, subMenu: 0}
                ]

                dataMap.forEach((m) => {
                    if (action.payload[m.name]) {
                        const preparedPayload = action.payload[m.name].map((item) => ({
                            ...item,
                            gtm: prepareGTMString(item.name)
                        }));

                        updatedState.menuItems[m.mainMenu].subItems[m.subMenu].subItems = preparedPayload;
                    }
                });

                updatedState = _merge({}, updatedState, action.payload);
            }
            break;
        default:
            break;
    }

    return updatedState
}
