import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _Get from 'lodash/get'
import _CloneDeep from 'lodash/cloneDeep'
import Select, { components } from 'react-select'

class Filters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            isSearching: {
                flavor: false,
                product: false,
                size: false,
                attribute: false,
                productLines: false,
            },
            groupedOptions: null,
        }
    }

    componentDidUpdate() {
        const { groupedOptions } = this.state
        const {
            flavors,
            products,
            packagings,
            attribute,
            productLines,
        } = this.props
        const isFiltersFilled =
            flavors.length &&
            packagings.length &&
            attribute.length &&
            productLines.length
        const productIsChanged =
            groupedOptions &&
            products &&
            products.length &&
            products !== groupedOptions[1].options

        if (!groupedOptions && isFiltersFilled) this.fillState()

        if (productIsChanged) this.fillState()
    }

    fillState() {
        const {
            attribute,
            flavors,
            products,
            packagings,
            productLines,
        } = this.props
        this.setState({
            groupedOptions: [
                { label: 'Flavor', options: flavors, type: 'flavor' },
                { label: 'Product', options: products, type: 'product' },
                { label: 'Size', options: packagings, type: 'package' },
                { label: 'Attribute', options: attribute, type: 'attribute' },
                {
                    label: 'Product line',
                    options: productLines,
                    type: 'productLines',
                },
            ],
        })
    }

    handleMultiSelectChange(type, options) {
        const { selections } = this.props
        if (selections) {
            const cloned = _CloneDeep(selections)
            cloned[type] = options
            this.props.onFilterChange(type, cloned)
        }
    }

    handleGroupLabelClick(e) {
        const {
            dataset: { type },
        } = e.currentTarget

        if (type) {
            const subs = e.currentTarget.parentNode.nextElementSibling
            const parent = e.currentTarget

            if (subs) {
                if (parent.getAttribute('class') === 'active') {
                    subs.style.display = 'none'
                    parent.setAttribute('class', '')
                } else {
                    subs.style.display = 'block'
                    parent.setAttribute('class', 'active')
                }
            }
        }
    }

    handleGroupMultiSelectChange(option) {
        const multipleTypes = {}
        const { groupedOptions } = this.state

        if (option.length)
            for (let opt of option) {
                const filtered = groupedOptions.filter(ent =>
                    !!ent.options.filter(o => o.id === opt.id).length
                        ? ent.type
                        : null
                )
                const type = filtered && filtered.length && filtered[0].type

                if (multipleTypes[type]) multipleTypes[type].push(opt)
                else multipleTypes[type] = [opt]

                if (type) {
                    if (type === 'product')
                        this.props.handleProductRedirect(opt)
                    else this.props.onFilterChange(type, multipleTypes)
                }
            }
        else this.props.onFilterClear()
    }

    handleSearchChange(type, value) {
        this.setState(prevState => ({
            isSearching: { ...prevState.isSearching, [type]: !!value },
        }))
    }

    renderMultiValue(isGrouped) {
        const [multipleTypes, singleTypes] = [[], {}]
        const { selections } = this.props
        const { groupedOptions } = this.state
        const processEntity = (type, item) => {
            if (item) {
                if (isGrouped) {
                    multipleTypes.push(item)
                } else {
                    if (singleTypes[type] && singleTypes[type].length) {
                        singleTypes[type].push(item)
                    } else {
                        singleTypes[type] = [item]
                    }
                }
            }
        }

        if (selections && groupedOptions) {
            for (let ent of Object.keys(selections)) {
                const selection = selections[ent]
                if (selection) {
                    if (typeof selection === 'object') {
                        if (selection.length) {
                            selection.forEach(e =>
                                groupedOptions.forEach(group => {
                                    const item = group.options.find(
                                        opt => opt.id === e
                                    )
                                    processEntity(ent, item)
                                })
                            )
                        } else {
                            Object.keys(selection).forEach(k =>
                                groupedOptions.forEach(group => {
                                    const item = group.options.find(
                                        opt => opt.id === k
                                    )
                                    processEntity(ent, item)
                                })
                            )
                        }
                    } else if (
                        typeof selection === 'string' &&
                        selection.includes(',')
                    ) {
                        const ids = selection.split(',')

                        groupedOptions.forEach(group => {
                            const item = group.options.find(opt => {
                                return (
                                    Array.isArray(opt.id) &&
                                    ids.some(e => opt.id.includes(e))
                                )
                            })
                            processEntity(ent, item)
                        })
                    } else {
                        groupedOptions.forEach(group => {
                            const item = group.options.find(
                                opt => opt.id === selection
                            )
                            processEntity(ent, item)
                        })
                    }
                }
            }
        }

        return isGrouped ? multipleTypes : singleTypes
    }

    render() {
        let desktopFilters = null
        let mobileFilters = null

        const { isSearching, groupedOptions } = this.state
        const CustomOptions = props => {
            return (
                <>
                    <components.Menu {...props}>
                        {props.children}
                    </components.Menu>
                </>
            )
        }
        const {
            attribute,
            flavors,
            isMobile,
            products,
            packagings,
            productLines,
        } = this.props

        if (!isMobile) {
            const multiValue = this.renderMultiValue()
            const getAdditionalCls = type => (isSearching[type] ? 'hidden' : '')
            const generateValues = type =>
                _Get(multiValue, type, []).map((v, i) => (
                    <span key={`${type}-value-${i}`}>{v.name}</span>
                ))
            // generate a dynamic key for the select component to force re-render when values change
            const getSelectDynamicKey = type => _Get(multiValue, type, [type]).join('')

            desktopFilters = (
                <div>
                    <div className="select-wraper">
                        <div className="title">Flavor</div>
                        <Select
                            key={getSelectDynamicKey('flavor')}
                            isMulti
                            isClearable
                            placeholder=""
                            options={flavors}
                            isSearchable={false}
                            className="select-big"
                            classNamePrefix="select"
                            getOptionValue={i => i.id}
                            getOptionLabel={i => i.name}
                            value={_Get(multiValue, 'flavor')}
                            onChange={opt =>
                                this.handleMultiSelectChange('flavor', opt)
                            }
                        />
                        <div className="values">{generateValues('flavor')}</div>
                    </div>
                    <div className="select-wraper">
                        <div className="title">
                            <span className={getAdditionalCls('product')}>
                                Product
                            </span>
                        </div>
                        <Select
                            isSearchable
                            placeholder=""
                            className="select-big"
                            classNamePrefix="select"
                            onBlurResetsInput={false}
                            getOptionValue={i => i.id}
                            onSelectResetsInput={false}
                            onChange={opt =>
                                this.props.handleProductRedirect(opt)
                            }
                            onInputChange={value =>
                                this.handleSearchChange('product', value)
                            }
                            getOptionLabel={i =>
                                `${i.name || ''} ${i.code ? `[${i.code}]` : ''}`
                            }
                            options={
                                products.length
                                    ? products
                                    : [{ name: 'Loading...', value: 0 }]
                            }
                        />
                    </div>
                    <div className="select-wraper">
                        <div className="title">Size</div>
                        <Select
                            key={getSelectDynamicKey('package')}
                            isMulti
                            isClearable
                            placeholder=""
                            isSearchable={false}
                            options={packagings}
                            className="select-big"
                            classNamePrefix="select"
                            getOptionValue={i => i.id}
                            getOptionLabel={i => i.name}
                            value={_Get(multiValue, 'package')}
                            onChange={opt =>
                                this.handleMultiSelectChange('package', opt)
                            }
                        />
                        <div className="values">
                            {generateValues('package')}
                        </div>
                    </div>
                    <div className="select-wraper">
                        <div className="title">Attribute</div>
                        <Select
                            key={getSelectDynamicKey('attribute')}
                            isMulti
                            isClearable
                            placeholder=""
                            options={attribute}
                            isSearchable={false}
                            className="select-big"
                            classNamePrefix="select"
                            getOptionValue={i => i.id}
                            getOptionLabel={i => i.name}
                            value={_Get(multiValue, 'attribute')}
                            onChange={opt =>
                                this.handleMultiSelectChange('attribute', opt)
                            }
                        />
                        <div className="values">
                            {generateValues('attribute')}
                        </div>
                    </div>
                    <div className="select-wraper">
                        <div className="title">Product line</div>
                        <Select
                            key={getSelectDynamicKey('productLines')}
                            isMulti
                            isClearable
                            placeholder=""
                            isSearchable={false}
                            className="select-big"
                            options={productLines}
                            classNamePrefix="select"
                            getOptionValue={i => i.id}
                            getOptionLabel={i => i.name}
                            value={_Get(multiValue, 'productLines')}
                            onChange={opt =>
                                this.handleMultiSelectChange(
                                    'productLines',
                                    opt
                                )
                            }
                        />
                        <div className="values">
                            {generateValues('productLines')}
                        </div>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            const multiValue = this.renderMultiValue(true)
            const formatGroupLabel = data => (
                <div
                    data-type={data.type}
                    onClick={this.handleGroupLabelClick.bind(this)}
                >
                    {data.label}
                </div>
            )

            mobileFilters = (
                <div className="select-wraper mobile">
                    <div className="title">Find a product</div>
                    <Select
                        isMulti
                        isClearable
                        placeholder=""
                        value={multiValue}
                        isSearchable={false}
                        className="select-big"
                        classNamePrefix="select"
                        getOptionValue={i => i.id}
                        hideSelectedOptions={false}
                        options={groupedOptions || []}
                        components={{ CustomOptions }}
                        formatGroupLabel={formatGroupLabel}
                        onChange={opt => this.handleGroupMultiSelectChange(opt)}
                        getOptionLabel={i =>
                            `${i.name || ''} ${i.code ? `[${i.code}]` : ''}`
                        }
                    />
                    <div className="values">
                        {multiValue.map((v, i) => (
                            <span key={`multi-value-${i}`}>{v.name}</span>
                        ))}
                    </div>
                </div>
            )
        }

        return (
            <div className="filters">
                {desktopFilters}
                {mobileFilters}
            </div>
        )
    }
}

Filters.defaultProps = {
    flavors: [],
    products: [],
    packagings: [],
    productLines: [],
}

const filterList = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.arrayOf(PropTypes.string.isRequired),
        ]),
        name: PropTypes.string.isRequired,
    })
)

Filters.propTypes = {
    attribute: filterList,
    flavors: filterList,
    products: filterList,
    selectedFlavor: PropTypes.string,
    packagings: filterList,
    productLines: filterList,

    onFilterChange: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isMobile: state.main.isMobile,
})

export default withRouter(connect(mapStateToProps)(Filters))
